import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      description:
        "Vogliamo un ambiente di lavoro stimolante, dove sentirsi a proprio agio, e condividiamo la nostra passione creando una community di developer e designer.",
    },
    title: {
      title: "Non un semplice luogo di lavoro",
      description:
        "Il lavoro è la nostra passione e ci dedichiamo il massimo impegno, ma riteniamo che per renderlo possibile si debba formare un gruppo coeso. Ecco perché mettiamo ogni giorno a rischio le nostre amicizie al calcio balilla.",
    },
    ourValues: {
      title: "I nostri valori",
      gridTitle1: "Ambizione",
      gridTitle2: "Ownership",
      gridTitle3: "Passione",
      gridDescription1:
        "Non ci piace porci limiti e difficilmente scendiamo a compromessi. Ci diamo la spinta a vicenda per migliorarci ogni giorno.",
      gridDescription2:
        "Ci prendiamo la responsabilità di ogni progetto, diamo il massimo per raggiungere il miglior risultato possibile, non solo per il cliente, ma anche per noi stessi.",
      gridDescription3:
        "È quella che muove ogni cosa, amiamo quello che facciamo e ci piace trasmetterlo nei nostri progetti.",
    },
    neverAlone: {
      title: "Non ci piace stare da soli",
      description1:
        "Ci piace condividere le nostre passioni e conoscenze confrontandoci con altre persone per arricchirci continuamente. Non crediamo in un sistema chiuso, ecco perché abbiamo creato dei format di eventi con l’obiettivo di creare delle community di designer e developer.",
      description2: "Sì, insomma, ci piace far festa.",
      eventDest1: "Per i developer",
      eventDest2: "Per i designer",
      eventDest3: "L’evento tech dedicato a tutti",
      eventLink: "Scopri di più",
      eventLinkAbsent: "In arrivo!",
    },
    bePartOfOurTeam: {
      title: "Vorresti far parte del nostro team?",
      description:
        "Visita la nostra pagina Careers per scoprire tutte le posizioni aperte o semplicemente per dare un’occhiata.",
      buttonText: "Vai a Careers",
    },
    greatPlaceToWork: {
      title1: "Mabiloft è un",
      title2: "Great Place to Work",
      R: "®",
      description:
        "Abbiamo ricevuto la certificazione Great Place to Work, con un Trust index del 100%",
    },
    aPlaceToBelong: {
      title: "Un luogo a cui appartenere",
      description1:
        "Per dare il massimo nel nostro lavoro crediamo sia necessario creare un ambiente che permetta di sentirsi a proprio agio. I nostri spazi sono in continua evoluzione, come noi. È importante ascoltare le esigenze di ognuno. Per prima cosa il nostro ufficio è fatto di persone.",
      description2:
        "Poi ci sono il biliardino, il tavolo da ping-pong e innumerevoli pouf per la pennichella dopo pranzo.",
    },
    makeOurEveryProject: {
      title: "Facciamo nostro ogni progetto",
      description1:
        "Il nostro lavoro non si limita al prodotto finale, è soprattutto ciò che succede nel mentre.",
      description2:
        "Tra eventi, cene di Natale e aperitivi improvvisati, ci piace mantenere rapporti di amicizia con i nostri clienti. Spesso e volentieri sono loro ad invitarci quindi, forse, siamo simpatici. ",
    },
    notOnlyCollegues: {
      title: "Non semplici colleghi",
      description1:
        "Non ci piace definirci semplici colleghi perché il legame tra noi va oltre le nostre scrivanie. Per noi è fondamentale creare un gruppo forte per portare a termine ogni progetto al meglio.",
      description2:
        "Crediamo nella forza del gruppo, dove ognuno con le proprie competenze e con la sua personalità è valorizzato allo stesso modo. Per noi è importante condividere esperienze che vanno oltre l’impegno lavorativo.",
    },
  },
  en: {
    seo: {
      description:
        "We want a inspiring workplace, where we can feel at ease, and we share our passion through a community of developers and designers.",
    },
    title: {
      title: "Not just a workplace",
      description:
        "Work is our passion and we put our utmost efforts into it, but we also think that this is only possible if we form a strong team. That's why everyday we challenge our friendship by playing table football.",
    },
    ourValues: {
      title: "Our values",
      gridTitle1: "Ambition",
      gridTitle2: "Ownership",
      gridTitle3: "Passion",
      gridDescription1:
        "We don't like to limit ourselves and hardly compromise. We push each other to improve everyday.",
      gridDescription2:
        "We take the responsibilty of every project, we give our best to reach the best possible result, not only for the client, but for ourselves, too.",
      gridDescription3:
        "That's our driving force, we love what we do and we like to convey that through our projects.",
    },
    neverAlone: {
      title: "We don't like to be alone",
      description1:
        "We like to share our passion and knowledge through exchange with others, to enrich ourselvers. We don't believe in a closed system, that's why we created an event format with the aim of creating a community of designers and developers.",
      description2: "Well, yes, we like to party.",
      eventDest1: "For developers",
      eventDest2: "For designers",
      eventDest3: "The tech event for everyone",
      eventLink: "Learn more",
      eventLinkAbsent: "Coming soon!",
    },
    bePartOfOurTeam: {
      title: "Would you like to join our team?",
      description:
        "Visit our Careers page to discover all the open position, or even just to take a look around.",
      buttonText: "Visit Careers",
    },
    greatPlaceToWork: {
      title1: "Mabiloft is a",
      title2: "Great Place to Work",
      R: "®",
      description:
        "We have been certified Great Place to Work, with a trust index of 100%",
    },
    aPlaceToBelong: {
      title: "A place to belong",
      description1:
        "In order to give our best we believe it is necessary to create an environment in which we feel at ease. Our work space is constantly evolving, just like us. It is important to listen to the need of everyone. In the first place our office is made of people.",
      description2:
        "Also, there is the football table, the ping pong table and countless innumerevoli bean bags for naps after lunch.",
    },
    makeOurEveryProject: {
      title: "We take ownership of every project",
      description1:
        "Our job is not limited to the final product, it mostly consists in what happens in the process.",
      description2:
        "With events, Christmas dinners and spontaneous gatherings, we like to maintain friendly relationships with our clients. They invite us over frequently, therefore maybe we are nice people. ",
    },
    notOnlyCollegues: {
      title: "Not just colleagues",
      description1:
        "We don't like to define ourselves as simply as colleagues because the bond between us is beyond our desks. It is essential to create a strong group to carry out our projects at best.",
      description2:
        "We believe in the strength of the group, where everyone with their own skills and personaliy is valued the same. It's important to us to share experiences besides the work engagement.",
    },
  },
};

export const l_lifeatm = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
