import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import APlaceToBelong from "./components/APlaceToBelong/APlaceToBelong";
import BePartOfOurTeam from "./components/BePartOfOurTeam/BePartOfOurTeam";
import GreatPlaceToWork from "./components/GreatPlaceToWork/GreatPlaceToWork";

import MakeOurEveryProject from "./components/MakeOurEveryProject/MakeOurEveryProject";
import NeverAlone from "./components/NeverAlone/NeverAlone";
import NotOnlyCollegues from "./components/NotOnlyCollegues/NotOnlyCollegues";
import OurValues from "./components/OurValues/OurValues";
import Title from "./components/Title/Title";
import { l_lifeatm } from "./locales";

const LifeAtMabiloft = () => {
  return (
    <>
      <SEO
        title="Life at Mabiloft"
        description={l_lifeatm("seo.description")}
      />

      <Title />
      <OurValues />
      <NeverAlone />
      <APlaceToBelong />
      <GreatPlaceToWork />
      <NotOnlyCollegues />
      <BePartOfOurTeam />
      <MakeOurEveryProject />

      <Footer />
    </>
  );
};

export default LifeAtMabiloft;
